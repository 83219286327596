/*     RADARY , ALADIN, CLOUDS, TEMP     */
.radar {
    width: 100%;
    max-width: 300px;
    height: 250px;
    position: relative;
    padding-top: 0;
}
.bleskhp .radar {
    height: 262px;
}
.radar .reload-button {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat -630px -513px;
    cursor: pointer;
    border-radius: 15px;
    display: block;
    height: 28px;
    position: absolute;
    right: 19px;
    top: 87px;
    z-index: 5;
    width: 28px;
    border: 1px solid #ddd;
}
.radar .ala,
.radar .clo,
.radar .tem {
    display: none;
}
.radar h2 {
    font-weight: normal;
}
.bleskhp .radar h2 {
    float: left;
    height: 30px;
    line-height: 36px;
    padding: 0 10px;
    position: relative;
    width: 280px;
    background-color: #23aaff;
    color: #fff;
    text-transform: uppercase;
    font-family: "tablet_gothic_condensed", arial, sans-serif;
}
.bleskhp .radar h2 a {
    color: #fff;
}
.bleskhp .radar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    font-size: 0;
    z-index: 50;
}
.radar .radar-control {
    background: #fff;
    height: 70px;
    display: none;
}
.radar .radar-control.bleskhp {
    height: 30px;
}
.radar .radar-control .detail_link {
    text-align: center;
    margin-top: 25px;
}
.radar .radar-control.bleskhp .detail_link {
    margin-top: 0;
    padding-top: 10px;
}
.radar .radar-control .detail_link a {
    color: #23aaff;
    font-size: 12px;
    font-weight: bold;
}
.radar .active {
    display: block !important;
}
.radar .map-all {
    border-top: #23aaff solid 1px;
    border-bottom: #23aaff solid 1px;
    position: relative;
}
.radar .map-all .leaflet-bar {
    box-shadow: none;
}
.radar .map-all .leaflet-bar a {
    border-radius: 15px;
    border: 2px solid #b6c1cc;
    color: #23aaff;
    margin-bottom: 7px;
}
.radar .map-all .anim-button {
    display: block;
    width: 40px;
    height: 40px;
    z-index: 40;
    position: absolute;
    left: 135px;
    top: 75px;
}
.radar .map-all .anim-button.start,
.radar .map-all .anim-button.start-aladin {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -2px -559px transparent;
}
.radar .map-all .anim-button.stop,
.radar .map-all .anim-button.stop-aladin {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -52px -559px transparent;

    display: none;
}
.radar .map-all .anim-button.start-aladin {
    display: none;
}
.radar #map-big {
    width: 620px;
    height: 455px;
}
.radar #map-big .anim-button {
    left: 300px;
    top: 200px;
}
.radar #map-small {
    width: 300px;
    height: 200px;
}
.radar .leaflet-image-layer {
    border: none;
    display: block;
    opacity: 0.7 !important;
}
.radar .tlacitka,
.radar .times {
    padding-top: 19px;
}
.radar .tlacitka .time-point,
.radar .tlacitka .time-point-aladin,
.radar .times .time-point,
.radar .times .time-point-aladin {
    position: relative;
    width: 17px;
    height: 6px;
    background: #b6c1cc;
    display: inline-block;
    font-size: 11px;
    color: white;
    cursor: pointer;
    text-align: center;
    padding: 1px;
    margin: 2px 3px 0 0;
    box-sizing: border-box;
}
.radar .tlacitka .time-point:first-of-type,
.radar .tlacitka .time-point-aladin:first-of-type,
.radar .times .time-point:first-of-type,
.radar .times .time-point-aladin:first-of-type {
    margin-left: 10px;
}
.radar .tlacitka .time-point .p-time,
.radar .tlacitka .time-point-aladin .p-time,
.radar .times .time-point .p-time,
.radar .times .time-point-aladin .p-time {
    position: absolute;
    top: -17px;
    font-size: 11px;
    display: none;
    color: #fff;
    padding: 2px;
    left: -8px;
    background: #23aaff;
}
.radar .tlacitka .time-point-aladin,
.radar .times .time-point-aladin {
    width: 50px;
    margin-left: 15px;
}
.radar .tlacitka .time-point-aladin:first-of-type,
.radar .times .time-point-aladin:first-of-type {
    margin-left: 20px;
}
.radar .tlacitka .time-point-aladin .p-time,
.radar .times .time-point-aladin .p-time {
    left: 0;
    width: 46px;
}
.radar .tlacitka .time-point-aladin .ar,
.radar .times .time-point-aladin .ar {
    left: 20px;
}
.radar .tlacitka .time-point-active,
.radar .times .time-point-active {
    background: #23aaff;
}
.radar .time-space {
    margin-right: 25px !important;
}
.radar .time-print {
    font-size: 11px;
    display: inline-block;
    margin: 5px 10px 0 30px;
    float: right;
    width: 10px;
    color: #707b87;
}
.radar .time-print:first-of-type {
    margin-right: 30px;
}
.radar .time-print:last-of-type {
    margin-left: 0;
    margin-right: 11px;
}
.radar .time-print-aladin {
    float: right;
    margin-top: 5px;
    margin-right: 59px;
    font-size: 11px;
    width: 10px;
}
.radar .time-print-aladin:first-of-type {
    margin-right: 51px;
}
.radar .arrow-right {
    font-size: 0;
    border-left: 6px solid #23aaff;
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
    position: relative;
    top: -4px;
    left: 6px;
}

.radar-detail {
    height: 550px;
}
.radar-detail .radar-control {
    height: 70px;
}
.radar-detail .radar-control .active {
    display: block;
}
.radar-detail .tlacitka,
.radar-detail .times {
    margin-left: 50px;
    padding-top: 31px;
}
.radar-detail .tlacitka .time-point,
.radar-detail .tlacitka .time-point-aladin,
.radar-detail .times .time-point,
.radar-detail .times .time-point-aladin {
    width: 19px;
}
.radar-detail .tlacitka .time-point .p-time,
.radar-detail .tlacitka .time-point-aladin .p-time,
.radar-detail .times .time-point .p-time,
.radar-detail .times .time-point-aladin .p-time {
    top: -33px;
    left: -12px;
    padding: 8px;
}
.radar-detail .tlacitka .time-point:first-of-type,
.radar-detail .tlacitka .time-point-aladin:first-of-type,
.radar-detail .times .time-point:first-of-type,
.radar-detail .times .time-point-aladin:first-of-type {
    margin-left: 0;
}
.radar-detail .tlacitka .time-point-aladin,
.radar-detail .times .time-point-aladin {
    width: 100px;
    margin-right: 12px;
}
.radar-detail .tlacitka .time-point-aladin:first-of-type,
.radar-detail .times .time-point-aladin:first-of-type {
    margin-left: 15px;
}
.radar-detail .tlacitka .time-point-aladin .p-time,
.radar-detail .times .time-point-aladin .p-time {
    left: 16px;
    width: 55px;
}
.radar-detail .tlacitka .time-point-aladin .ar,
.radar-detail .times .time-point-aladin .ar {
    left: 48px;
}
.radar-detail .time-print {
    width: 15px;
    margin-left: 30px;
}
.radar-detail .time-print:first-of-type {
    margin-right: 60px;
}
.radar-detail .time-print-aladin {
    width: 10px;
    float: right;
    margin: 5px 108px 0 10px;
    font-size: 11px;
    color: #16212d;
}
.radar-detail .time-print-aladin:first-of-type {
    margin-right: 126px;
}
.radar-detail .ar {
    font-size: 0;
    line-height: 0%;
    width: 0;
    border-top: 6px solid #23aaff;
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
    position: absolute;
    top: -7px;
    left: 5px;
    display: none;
}

.obec .radar .map-all .anim-button {
    top: 120px;
}

.radar-data {
    display: none;
}

.radar-tabs {
    background: #fff;
    padding: 10px 0;
}
.radar-tabs .tabs {
    cursor: pointer;
    text-align: center;
    float: left;
    width: 73px;
    border-right: 1px solid #b6c1cc;
    font-size: 12px;
    color: #16212d;
    font-family: "Arial";
}
.radar-tabs .tabs h2 {
    font-weight: normal;
}
.radar-tabs .tabs .tab-pic {
    width: 40px;
    height: 50px;
    margin: 0 auto;
}
.radar-tabs .tabs .radar {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -322px -555px transparent;
}
.radar-tabs .tabs .aladin {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -175px -558px transparent;
}
.radar-tabs .tabs .clouds {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -235px -559px transparent;
}
.radar-tabs .tabs .temperature {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -280px -552px transparent;
}
.radar-tabs .noBord {
    border: none !important;
}
.radar-tabs .tabs.active {
    position: relative;
    color: #23aaff;
}
.radar-tabs .tabs.active .radar {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -322px -507px;
}
.radar-tabs .tabs.active .aladin {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -174px -509px transparent;
}
.radar-tabs .tabs.active .clouds {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -235px -508px transparent;
}
.radar-tabs .tabs.active .temperature {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -280px -502px transparent;
}
.radar-tabs .tabs.active .arrow-down {
    font-size: 0;
    border-top: 10px solid #23aaff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 72px;
    left: 30px;
    z-index: 10;
}
.radar-tabs .tabs.active .arrow_down_inner {
    font-size: 0;
    border-top: 10px solid #fff;
    top: 71px;
    left: 30px;
    z-index: 11;
}

.radar-tabs.radar-tabs-detail {
    background: #eee;
    padding: 0;
}
.radar-tabs.radar-tabs-detail .tabs {
    width: 154px;
}
.radar-tabs.radar-tabs-detail .tabs .tab-name {
    padding-bottom: 5px;
}
.radar-tabs.radar-tabs-detail .tabs.active {
    background: #23aaff;
    color: #fff;
}
.radar-tabs.radar-tabs-detail .tabs.active .arrow-down {
    left: 70px;
    top: 62px;
}
.radar-tabs.radar-tabs-detail .tabs.active .radar {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -365px -555px transparent;
}
.radar-tabs.radar-tabs-detail .tabs.active .aladin {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -175px -558px transparent;
}
.radar-tabs.radar-tabs-detail .tabs.active .clouds {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -235px -558px transparent;
}
.radar-tabs.radar-tabs-detail .tabs.active .temperature {
    background: url("/images/blesk-pocasi/weather-sprites.png?v=200") no-repeat scroll -280px -552px transparent;
}
.radar-tabs.radar-tabs-detail .tabs.active .arrow_down_inner {
    left: 59px;
    border-top: 20px solid #23aaff;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    top: 62px;
}
